var jschardet = require('jschardet');
const fileReader = new FileReader();

const DEFAULT_CSV_ENCODING = 'UTF-8';

const getFileEncoding = (file) => {
  return new Promise((resolve) => {
    fileReader.onload = () => {
      resolve(jschardet.detect(fileReader.result).encoding);
    };
    fileReader.readAsBinaryString(file);
  });
};

const readFile = (file, encoding = DEFAULT_CSV_ENCODING) => {
  return new Promise((resolve) => {
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.readAsText(file, encoding);
  });
};

export { readFile, getFileEncoding };
