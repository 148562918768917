import React, { useState, useEffect } from 'react';
import EnhancedTable from '../components/EnhancedTable';
import TableHeader from '../components/TableHeader';
import { Button } from '@amzn/awsui-components-react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSchedulesApi,
  addAvailableSchedulesApi,
} from '../actions/schedules';
import {
  SCHEDULE_FIELDS,
  SCHEDULE_FIELDS_HEADER_MAPPING,
} from '../../constants/scheduleDataConfig';
import UploadCsvFileModal from '../components/UploadCsvFileModal';
import DeleteScheduleModal from '../components/DeleteScheduleModal';
import UploadRequirements from '../components/UploadRequirements';
import getCsvGeneratedJsonErrors from '../../helpers/scheduleParsing/getCsvGeneratedJsonErrors';
import transformCsvGeneratedJsonToApiJson from '../../helpers/scheduleParsing/transformCsvGeneratedJsonToApiJson';
import { clearAllBannerMessages } from '../actions/bannerMessages';
import {
  timeComparator,
  dateComparator,
} from '../../helpers/tableComparators.js';
import RadioButton from '@amzn/awsui-components-react/polaris/radio-button';
import Input from '@amzn/awsui-components-react/polaris/input';
import EditScheduleModal from '../components/scheduleManager/EditScheduleModal';
import BulkEditScheduleModal from '../components/scheduleManager/BulkEditScheduleModal';
import MultiDeleteSchedulesModal from '../components/MultiDeleteSchedulesModal';
import MultiEditScheduleModal from '../components/scheduleManager/MultiEditScheduleModal';
import csvFile from '../assets/upload_schedule_template.csv';

const ScheduleManagerPage = () => {
  const [currentSelection, setCurrentSelection] = useState([]);
  const [isEditScheduleModalVisible, setIsEditScheduleModalVisible] = useState(
    false
  );
  const [
    isBulkEditScheduleModalVisible,
    setIsBulkEditScheduleModalVisible,
  ] = useState(false);
  const [
    isMultiEditScheduleModalVisible,
    setIsMultiEditScheduleModalVisible,
  ] = useState(false);
  const [
    isUploadSchedulesModalVisible,
    setIsUploadSchedulesModalVisible,
  ] = useState(false);
  const [isClassIdFieldVisible, setIsClassIdFieldVisible] = useState(false);
  const [classId, setClassId] = useState(null);

  const [
    isDeleteScheduleModalVisible,
    setIsDeleteScheduleModalVisible,
  ] = useState(false);
  const [
    isMultiDeleteSchedulesModalVisible,
    setIsMultiDeleteSchedulesModalVisible,
  ] = useState(false);
  const schedules = useSelector((state) => state.schedules);
  const schedulesLoading = useSelector(
    (state) => state.loading.schedulesLoading
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearAllBannerMessages());
    dispatch(getSchedulesApi());
  }, []);
  const specialSortableColumnsComparatorsObj = {
    dayOne: dateComparator('dayOne'),
    startTime: timeComparator('startTime'),
  };
  const HeaderComponent = () => (
    <TableHeader
      title={'Schedule Manager'}
      onRefresh={() => dispatch(getSchedulesApi())}
      buttons={[
        <Button
          key="editSchedule"
          disabled={currentSelection.length === 0}
          onClick={() => {
            currentSelection.length === 1
              ? setIsEditScheduleModalVisible(true)
              : setIsMultiEditScheduleModalVisible(true);
          }}
        >
          Edit Available Number
        </Button>,
        <Button
          key="deleteSchedules"
          disabled={currentSelection.length === 0}
          onClick={() =>
            currentSelection.length === 1
              ? setIsDeleteScheduleModalVisible(true)
              : setIsMultiDeleteSchedulesModalVisible(true)
          }
        >
          Delete
        </Button>,
        <Button
          key="bulkEditSchedule"
          onClick={() => setIsBulkEditScheduleModalVisible(true)}
        >
          Adjust a Class
        </Button>,
        <Button
          key="uploadSchedulesCSV"
          variant="primary"
          onClick={() => setIsUploadSchedulesModalVisible(true)}
        >
          Upload Schedules CSV
        </Button>,
      ]}
    />
  );

  const UploadSchedulesModalDescription = () => (
    <div>
      <div>
        Click the &apos;Choose File&apos; button below and select a file to be
        uploaded. Uploaded files must be in a .CSV format. If you are unsure how
        the file should look, please{' '}
        <a href={csvFile} download="upload_schedules_template.csv">
          download the schedules template
        </a>
        .
      </div>
    </div>
  );

  const UploadSchedulesModalRequirements = () => <UploadRequirements />;

  const CustomizedOptions = () => (
    <div className="awsui-grid">
      <RadioButton
        className="awsui-util-mt-s"
        value="create-class-id"
        checked={!isClassIdFieldVisible}
        onChange={() => {
          setIsClassIdFieldVisible(false);
          setClassId(null);
        }}
      >
        Create new class id
      </RadioButton>
      <RadioButton
        className="awsui-util-mt-s awsui-util-mb-s"
        value="use-existed-class-id"
        checked={isClassIdFieldVisible}
        onChange={() => {
          setIsClassIdFieldVisible(true);
        }}
      >
        Use existed class id
      </RadioButton>
      {isClassIdFieldVisible && (
        <Input
          className="col-6 awsui-util-mb-s"
          placeholder="Class Id"
          onChange={({ detail }) => setClassId(detail.value)}
          value={classId}
        />
      )}
    </div>
  );
  return (
    <>
      <EnhancedTable
        headers={SCHEDULE_FIELDS.map(
          (field) => SCHEDULE_FIELDS_HEADER_MAPPING[field]
        )}
        dataKeys={SCHEDULE_FIELDS}
        data={schedules}
        loading={schedulesLoading}
        loadingText="Schedules loading"
        enableAction={true}
        setCurrentSelection={setCurrentSelection}
        currentSelection={currentSelection}
        selectionType="multi"
        HeaderComponent={HeaderComponent}
        specialSortableColumnsComparatorsObj={
          specialSortableColumnsComparatorsObj
        }
        showTimeZoneMapping={true}
      />
      <BulkEditScheduleModal
        isModalVisible={isBulkEditScheduleModalVisible}
        setIsModalVisible={setIsBulkEditScheduleModalVisible}
      />
      <MultiEditScheduleModal
        currentSelections={currentSelection}
        resetCurrentSelections={setCurrentSelection}
        isModalVisible={isMultiEditScheduleModalVisible}
        setIsModalVisible={setIsMultiEditScheduleModalVisible}
      />
      <EditScheduleModal
        title="Edit Available Number"
        selectedSchedule={currentSelection[0]}
        isModalVisible={isEditScheduleModalVisible}
        setIsModalVisible={setIsEditScheduleModalVisible}
      />
      <DeleteScheduleModal
        selectedSchedule={currentSelection[0]}
        isModalVisible={isDeleteScheduleModalVisible}
        setIsModalVisible={setIsDeleteScheduleModalVisible}
      />
      <MultiDeleteSchedulesModal
        currentSelections={currentSelection}
        isModalVisible={isMultiDeleteSchedulesModalVisible}
        setIsModalVisible={setIsMultiDeleteSchedulesModalVisible}
      />
      <UploadCsvFileModal
        title="Upload Schedules"
        Description={UploadSchedulesModalDescription}
        Requirements={UploadSchedulesModalRequirements}
        fileValidator={getCsvGeneratedJsonErrors}
        uploadFile={(schedulesJson) => {
          if (classId) {
            schedulesJson.forEach(
              (schedule) => (schedule.classId = parseInt(classId))
            );
          }
          dispatch(addAvailableSchedulesApi(schedulesJson));
        }}
        dataParser={transformCsvGeneratedJsonToApiJson}
        isModalVisible={isUploadSchedulesModalVisible}
        setIsModalVisible={setIsUploadSchedulesModalVisible}
        CustomizedOptions={CustomizedOptions}
      />
    </>
  );
};

export default ScheduleManagerPage;
