import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { SCHEDULE_EDITING_MODE_MAPPING } from '../../../constants/scheduleDataConfig';
import {
  Button,
  ColumnLayout,
  Modal,
  FormField,
  Input,
  ButtonDropdown,
} from '@amzn/awsui-components-react/polaris';
import '../../styles/multiEditScheduleModal.scss';
import { multiEditScheduleAvailableNumberApi } from '../../actions/schedules';

const MultiEditScheduleModal = ({
  currentSelections,
  resetCurrentSelections,
  isModalVisible,
  setIsModalVisible,
}) => {
  const slotAdjustOptions = [
    {
      text: 'Add slots',
      id: '0',
    },
    {
      text: 'Remove slots',
      id: '1',
    },
    {
      text: 'Set to',
      id: '2',
    },
  ];
  const defaultMultiEditInfo = {
    available: '',
    selectedOption: slotAdjustOptions[0],
  };
  const dispatch = useDispatch();
  const onSubmit = () => {
    dispatch(
      multiEditScheduleAvailableNumberApi({
        available: parseInt(multiEditInfo.available),
        mode: SCHEDULE_EDITING_MODE_MAPPING[multiEditInfo.selectedOption.text],
        scheduleIds: currentSelections.map((schedule) => schedule.scheduleId),
      })
    );
    resetCurrentSelections([]);
    setIsModalVisible(false);
  };
  const onDismissModal = () => {
    setIsModalVisible(false);
  };
  const [multiEditInfo, setMultiEditInfo] = useState(defaultMultiEditInfo);

  const modalHeaderText = 'Edit Schedules';
  const modalCancelText = 'Cancel';
  const modalSubmitText = 'Update';

  return (
    <Modal
      visible={isModalVisible}
      onDismiss={onDismissModal}
      header={modalHeaderText}
      footer={
        <div className="awsui-util-f-r">
          <Button onClick={onDismissModal}>{modalCancelText}</Button>
          <Button variant="primary" onClick={onSubmit}>
            {modalSubmitText}
          </Button>
        </div>
      }
    >
      <div id="bulkEditScheduleForm" className="multi-edit-modal-space">
        <ColumnLayout columns={3}>
          <div data-awsui-column-layout-root="true">
            <span>Add or remove slots?</span>
            <span>How Many?</span>
          </div>
        </ColumnLayout>
        <FormField key="available">
          <ColumnLayout columns={3}>
            <div data-awsui-column-layout-root="true">
              <ButtonDropdown
                items={slotAdjustOptions}
                width="50px"
                onItemClick={(val) => {
                  setMultiEditInfo({
                    ...multiEditInfo,
                    selectedOption: slotAdjustOptions[parseInt(val.detail.id)],
                  });
                }}
              >
                {multiEditInfo.selectedOption.text}
              </ButtonDropdown>
              <div className="slotInputSpace">
                <Input
                  className="slotInputSpace"
                  id="available"
                  value={multiEditInfo.available.toString()}
                  placeholder="E.g., 6"
                  onChange={(val) => {
                    setMultiEditInfo({
                      ...multiEditInfo,
                      available: val.detail.value,
                    });
                  }}
                />
              </div>
            </div>
          </ColumnLayout>
        </FormField>
      </div>
    </Modal>
  );
};

MultiEditScheduleModal.propTypes = {
  currentSelections: PropTypes.array,
  resetCurrentSelections: PropTypes.func,
  isModalVisible: PropTypes.bool,
  setIsModalVisible: PropTypes.func,
};
export default MultiEditScheduleModal;
