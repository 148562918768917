import React, { useState } from 'react';
import {
  Button,
  ColumnLayout,
  Modal,
  FormField,
  Input,
  ButtonDropdown,
} from '@amzn/awsui-components-react/polaris';
import { SCHEDULE_EDITING_MODE_MAPPING } from '../../../constants/scheduleDataConfig';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { bulkEditScheduleApi } from '../../actions/schedules';
import '../../styles/bulkEditScheduleModal.scss';

const BulkEditScheduleModal = ({ isModalVisible, setIsModalVisible }) => {
  const slotAdjustOptions = [
    {
      text: 'Add slots',
      id: '0',
    },
    {
      text: 'Remove slots',
      id: '1',
    },
    {
      text: 'Set to',
      id: '2',
    },
  ];
  const defaultBulkEditInfo = {
    classId: '',
    available: '',
    selectedOption: slotAdjustOptions[0],
  };
  const [bulkEditInfo, setBulkEditInfo] = useState(defaultBulkEditInfo);
  const dispatch = useDispatch();
  const onDismissModal = () => {
    setIsModalVisible(false);
  };
  const onSubmit = () => {
    dispatch(
      bulkEditScheduleApi({
        mode: SCHEDULE_EDITING_MODE_MAPPING[bulkEditInfo.selectedOption.text],
        classId: parseInt(bulkEditInfo.classId),
        available: parseInt(bulkEditInfo.available),
      })
    );
    setBulkEditInfo(defaultBulkEditInfo);
    setIsModalVisible(false);
  };
  const modalHeaderText = 'Adjust a class';
  const modalCancelText = 'Cancel';
  const modalSubmitText = 'Update';
  return (
    <Modal
      visible={isModalVisible}
      onDismiss={onDismissModal}
      header={modalHeaderText}
      footer={
        <div className="awsui-util-f-r">
          <Button onClick={onDismissModal}>{modalCancelText}</Button>
          <Button variant="primary" onClick={onSubmit}>
            {modalSubmitText}
          </Button>
        </div>
      }
    >
      <div id="bulkEditScheduleForm">
        <FormField label="Class ID" key="classId">
          <div className="classIdInputBlockSpace">
            <Input
              id="classId"
              value={bulkEditInfo.classId.toString()}
              placeholder="Enter the Class ID"
              onChange={(val) => {
                setBulkEditInfo({
                  ...bulkEditInfo,
                  classId: parseInt(val.detail.value),
                });
              }}
            />
          </div>
        </FormField>
        <ColumnLayout columns={3}>
          <div data-awsui-column-layout-root="true">
            <span>Add or remove slots?</span>
            <span>How Many?</span>
          </div>
        </ColumnLayout>
        <FormField key="available">
          <ColumnLayout columns={3}>
            <div data-awsui-column-layout-root="true">
              <ButtonDropdown
                items={slotAdjustOptions}
                width="50px"
                onItemClick={(val) => {
                  setBulkEditInfo({
                    ...bulkEditInfo,
                    selectedOption: slotAdjustOptions[parseInt(val.detail.id)],
                  });
                }}
              >
                {bulkEditInfo.selectedOption.text}
              </ButtonDropdown>
              <div className="slotInputSpace">
                <Input
                  className="slotInputSpace"
                  id="available"
                  value={bulkEditInfo.available.toString()}
                  placeholder="E.g., 6"
                  onChange={(val) => {
                    setBulkEditInfo({
                      ...bulkEditInfo,
                      available: val.detail.value,
                    });
                  }}
                />
              </div>
            </div>
          </ColumnLayout>
        </FormField>
      </div>
    </Modal>
  );
};
BulkEditScheduleModal.propTypes = {
  isModalVisible: PropTypes.bool,
  setIsModalVisible: PropTypes.func,
};
export default BulkEditScheduleModal;
