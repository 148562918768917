import React from 'react';
import { ExpandableSection } from '@amzn/awsui-components-react';
import Box from '@amzn/meridian/box';
import Text from '@amzn/meridian/text';

const UploadRequirements = () => {
  return (
    <ExpandableSection header="Upload Requirements">
      <Box spacingInset="large" backgroundColor="secondary">
        <Text>
          Your uploaded schedules must include the following columns that are
          important to track candidates as they move through the scheduling
          process:
        </Text>
        <ul>
          <li>
            <Text>
              Day One - this will be the Sunday of the first week the candidate
              will start.
            </Text>
          </li>
          <li>
            <Text>
              OU ID - this is the Organizational Unit to which the candidate
              will be assigned. (Example: Retail, SDS, etc.)
            </Text>
          </li>
          <li>
            <Text>
              Site - this is the Site where the candidate will be assigned.
              (Example: VCSNA, WIN, etc.)
            </Text>
          </li>
          <li>
            <Text>
              Workgroup Name - this is the name of the candidate's workgroup.
              (Example: AMZL-US-Driver, Eco_Retail_P2_TBR_Phone, etc.)
            </Text>
          </li>
        </ul>
        <Text>
          After uploading your schedules, the Class ID column will be
          automatically generated for you and assigned to them. It is an
          important column that will determine a candidate's ability to see
          available schedules posted:
        </Text>
        <ul>
          <li>
            <Text>
              Class ID - this ID (formerly known as the Batch ID) is auto
              assigned in the upload. So each upload of schedules will need to
              be intended for one hiring class. In other words — one upload per
              hiring class.
            </Text>
          </li>
        </ul>
      </Box>
    </ExpandableSection>
  );
};

export default UploadRequirements;
