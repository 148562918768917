const REQUIRED_HEADERS = [
  'startTime',
  'durationInHours',
  'available',
  'ouId',
  'workgroupName',
  'dayOne',
  'site',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
  'shortBreak1',
  'shortBreak2',
  'lunchBreak1',
  'lunchBreak2',
  'language',
];

const REQUIRED_NONEMPTY_FIELDS = [
  'startTime',
  'durationInHours',
  'available',
  'ouId',
  'workgroupName',
  'dayOne',
  'site',
  'shortBreak1',
  'lunchBreak1',
  'language',
];

const LUNCH_HEADERS = [
  'shortBreak1',
  'shortBreak2',
  'lunchBreak1',
  'lunchBreak2',
];

export { REQUIRED_HEADERS, REQUIRED_NONEMPTY_FIELDS, LUNCH_HEADERS };
