import React, { useState, useEffect } from 'react';
import { Button, Modal } from '@amzn/awsui-components-react/polaris';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { editScheduleApi } from '../../actions/schedules';
import { Input } from '@amzn/awsui-components-react/polaris';
import { get } from 'lodash';
const EditScheduleModal = ({
  selectedSchedule,
  isModalVisible,
  setIsModalVisible,
}) => {
  useEffect(() => {
    setEditedSchedule(selectedSchedule);
  }, [selectedSchedule]);
  const [editedSchedule, setEditedSchedule] = useState(selectedSchedule);
  const dispatch = useDispatch();
  const onClose = () => {
    setEditedSchedule(selectedSchedule);
    setIsModalVisible(false);
  };
  const onSubmit = () => {
    dispatch(
      editScheduleApi({
        scheduleId: editedSchedule.scheduleId,
        available: editedSchedule.available,
      })
    );
    setIsModalVisible(false);
  };
  const modalHeaderText = 'Edit Available Number';
  const modalCancelText = 'Cancel';
  const modalSubmitText = 'Update';
  return (
    <>
      <Modal
        onDismiss={onClose}
        visible={isModalVisible}
        size="medium"
        footer={
          <>
            <Button onClick={onClose}>{modalCancelText}</Button>
            <Button variant="primary" onClick={onSubmit}>
              {modalSubmitText}
            </Button>
          </>
        }
        header={modalHeaderText}
      >
        <div id="editScheduleForm">
          <Input
            value={get(editedSchedule, 'available', '').toString()}
            onChange={(val) => {
              setEditedSchedule({
                ...editedSchedule,
                available: parseInt(val.detail.value),
              });
            }}
          />
        </div>
      </Modal>
    </>
  );
};
EditScheduleModal.propTypes = {
  selectedSchedule: PropTypes.object,
  isModalVisible: PropTypes.bool,
  setIsModalVisible: PropTypes.func,
};
export default EditScheduleModal;
