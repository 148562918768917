import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  ColumnLayout,
  Modal,
} from '@amzn/awsui-components-react/polaris';
import {
  SCHEDULE_FIELDS_HEADER_MAPPING,
  SCHEDULE_DELETE_DISPLAY_FIELDS,
} from '../../constants/scheduleDataConfig';
import { deleteAvailableScheduleApi } from '../actions/schedules';
import { get } from 'lodash';

const DeleteScheduleModal = ({
  selectedSchedule,
  isModalVisible,
  setIsModalVisible,
}) => {
  const dispatch = useDispatch();
  const onClose = () => {
    setIsModalVisible(false);
  };
  const onSubmit = () => {
    const scheduleId = selectedSchedule.scheduleId;
    dispatch(deleteAvailableScheduleApi(scheduleId));
    setIsModalVisible(false);
  };
  const modalHeaderText = 'Are you sure you want to delete this schedule?';
  const modalCancelText = 'Cancel';
  const modalSubmitText = 'Delete';
  return (
    <Modal
      onDismiss={onClose}
      visible={isModalVisible}
      header={modalHeaderText}
      footer={
        <span className="awsui-util-f-r">
          <Button variant="link" onClick={onClose}>
            {modalCancelText}
          </Button>
          <Button variant="primary" onClick={onSubmit}>
            {modalSubmitText}
          </Button>
        </span>
      }
    >
      <ColumnLayout columns={2}>
        {SCHEDULE_DELETE_DISPLAY_FIELDS.map((element) => (
          <div data-awsui-column-layout-root="true" key={element}>
            <div>
              <b>{get(SCHEDULE_FIELDS_HEADER_MAPPING, [element], '')}</b>
            </div>
            <div>{get(selectedSchedule, [element], '')}</div>
          </div>
        ))}
      </ColumnLayout>
    </Modal>
  );
};

export default DeleteScheduleModal;
