// import { LUNCH_HEADERS } from '../../constants/schedulesCsv';

const transformCsvGeneratedJsonToApiJson = (json) => {
  return json.map((schedule) => {
    return {
      startTime: schedule.startTime,
      durationInHours: Number(schedule.durationInHours),
      available: Number(schedule.available),
      ouId: schedule.ouId,
      workGroup: schedule.workgroupName,
      dayOne: schedule.dayOne,
      site: schedule.site,
      language: schedule.language,
      workingDays: {
        monday: Boolean(schedule.monday),
        tuesday: Boolean(schedule.tuesday),
        wednesday: Boolean(schedule.wednesday),
        thursday: Boolean(schedule.thursday),
        friday: Boolean(schedule.friday),
        saturday: Boolean(schedule.saturday),
        sunday: Boolean(schedule.sunday),
      },
      lunchBreaks: schedule.lunchBreak2
        ? [
            { startTime: schedule.lunchBreak1, durationInMins: 45 },
            { startTime: schedule.lunchBreak2, durationInMins: 45 },
          ]
        : [{ startTime: schedule.lunchBreak1, durationInMins: 60 }],
      shortBreaks: schedule.shortBreak2
        ? [
            { startTime: schedule.shortBreak1, durationInMins: 15 },
            { startTime: schedule.shortBreak2, durationInMins: 15 },
          ]
        : [{ startTime: schedule.shortBreak1, durationInMins: 15 }],
    };
  });
};

// const extractLunchBreaks = (schedule) => {
//   const lunchBreaks = [];
//   const durationMinutesRegex = /(\d\d)[Mm]in/;

//   LUNCH_HEADERS.forEach((lunchType) => {
//     if (schedule[lunchType]) {
//       const minutesString = lunchType.match(durationMinutesRegex)[1];
//       const minutes = Number(minutesString);
//       lunchBreaks.push({
//         startTime: schedule[lunchType],
//         durationInMins: minutes,
//       });
//     }
//   });

//   return lunchBreaks;
// };

// const extractShortBreaks = (schedule) => {
//   const shortBreaks = [];

//   const breakTypes = ['shortBreak1', 'shortBreak2'];

//   breakTypes.forEach((breakType) => {
//     if (schedule[breakType]) {
//       shortBreaks.push({
//         startTime: schedule[breakType],
//         durationInMins: 15,
//       });
//     }
//   });

//   return shortBreaks;
// };

export default transformCsvGeneratedJsonToApiJson;
