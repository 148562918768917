import {
  ADD_SCHEDULES,
  SCHEDULES_ARE_LOADING,
  UPDATE_SCHEDULES,
  DELETE_SCHEDULE,
} from './types';
import {
  GET_SCHEDULES_ENDPOINT,
  ADD_AVAILABLE_SCHEDULES_ENDPOINT,
  DELETE_AVAILABLE_SCHEDULE_ENDPOINT,
  headers,
  EDIT_SCHEDULE_AVAILABLE_NUMBER,
  BULK_EDIT_SCHEDULE_AVAILABLE_NUMBER,
  MULTI_DELETE_AVAILABLE_SCHEDULE_ENDPOINT,
  MULTI_EDIT_SCHEDULE_AVAILABLE_NUMBER,
} from '../../constants/apiConfigs';
import { validationErrorGenerator } from '../../helpers/validationErrorHelper';
import axios from 'axios';
import { get } from 'lodash';
import {
  showSuccessBanner,
  showErrorBanner,
  showWarningBanner,
} from './bannerMessages';

export function addSchedules(schedules) {
  return {
    type: ADD_SCHEDULES,
    payload: schedules,
  };
}

export function updateSchedules(schedules) {
  return {
    type: UPDATE_SCHEDULES,
    payload: schedules,
  };
}

export function schedulesAreLoading(isLoading) {
  return {
    type: SCHEDULES_ARE_LOADING,
    payload: isLoading,
  };
}

export function deleteSchedule(scheduleId) {
  return {
    type: DELETE_SCHEDULE,
    payload: scheduleId,
  };
}

/*API calls */
export function getSchedulesApi() {
  return async (dispatch) => {
    dispatch(schedulesAreLoading(true));
    return axios
      .get(GET_SCHEDULES_ENDPOINT, {
        headers: await headers(),
      })
      .then((res) => {
        if (res.status !== 200) {
          dispatch(
            showErrorBanner(
              `Failed to retrieve schedule(s). Detail from server: ${get(
                res,
                'message',
                'None'
              )} `
            )
          );
        }
        return res;
      })
      .then((res) =>
        dispatch(addSchedules(get(res, 'data.availableSchedules', [])))
      )
      .catch((e) => {
        dispatch(
          showErrorBanner(
            `Failed to retrieve schedule(s). Detail from server: ${get(
              e,
              'response.data.message',
              e.message
            )} `
          )
        );
      })
      .then(() => dispatch(schedulesAreLoading(false)));
  };
}

export function addAvailableSchedulesApi(schedules) {
  return async (dispatch) => {
    dispatch(schedulesAreLoading(true));
    return axios
      .post(
        ADD_AVAILABLE_SCHEDULES_ENDPOINT,
        {
          availableSchedules: schedules,
        },
        { headers: await headers() }
      )
      .then((res) => {
        if (res.status !== 200) {
          throw Error(res.statusText);
        }
        return res;
      })
      .then(() => {
        dispatch(getSchedulesApi());
        dispatch(
          showSuccessBanner(
            `Successfully added ${schedules.length} new schedule${
              schedules.length === 1 ? '' : 's'
            }. Please sort Class Id column by descending or search for biggest class Id to see the updates.`
          )
        );
      })
      .catch((e) => {
        dispatch(
          dispatch(
            showErrorBanner(
              `Failed to retrieve schedule(s). Detail from server: ${validationErrorGenerator(
                get(e, 'response.data.message', e.message)
              )} `
            )
          )
        );
      })
      .then(() => dispatch(schedulesAreLoading(false)));
  };
}

export function editScheduleApi({ scheduleId, available }) {
  return async (dispatch) => {
    dispatch(schedulesAreLoading(true));
    return axios
      .post(
        EDIT_SCHEDULE_AVAILABLE_NUMBER,
        {
          scheduleId,
          available,
        },
        { headers: await headers() }
      )
      .then((res) => {
        if (res.status !== 200) {
          throw Error(res.statusText);
        }
        return res;
      })
      .then(() => {
        dispatch(getSchedulesApi());
        dispatch(
          showSuccessBanner(
            `Successfully Update available number in schedule with ID: ${scheduleId}`
          )
        );
      })
      .catch((e) => {
        dispatch(
          showErrorBanner(
            `Failed to update available number in schedule with ID: ${scheduleId}. Detail from server: ${get(
              e,
              'response.data.message',
              e.message
            )} `
          )
        );
      })
      .then(() => dispatch(schedulesAreLoading(false)));
  };
}

export function multiEditScheduleAvailableNumberApi({
  mode,
  available,
  scheduleIds,
}) {
  return async (dispatch) => {
    dispatch(schedulesAreLoading(true));
    return axios
      .post(
        MULTI_EDIT_SCHEDULE_AVAILABLE_NUMBER,
        {
          mode: mode,
          available: available,
          scheduleIds: scheduleIds,
        },
        { headers: await headers() }
      )
      .then((res) => {
        if (res.status !== 200) {
          throw Error(res.statusText);
        }
        const failedScheduleIds = get(res, 'data.failedScheduleIds', []);
        if (failedScheduleIds.length > 0) {
          dispatch(
            showWarningBanner(
              `Failed to update schedule(s) with ID: ${failedScheduleIds.join(
                ', '
              )}. These schedules do not exist. Please refresh and try again.`
            )
          );
        }
        return failedScheduleIds;
      })
      .then((failedScheduleIds) => {
        const updatedSchedules = scheduleIds.filter(
          (schedule) => !failedScheduleIds.includes(schedule)
        );
        dispatch(getSchedulesApi());
        if (updatedSchedules.length > 0) {
          dispatch(
            showSuccessBanner(
              `Successfully updated schedules with ID: ${updatedSchedules}`
            )
          );
        }
      })
      .catch((e) => {
        dispatch(
          showErrorBanner(
            `Failed to update schedules with ID: ${scheduleIds}. Detail from server: ${get(
              e,
              'response.data.message',
              e.message
            )} `
          )
        );
      })
      .then(() => dispatch(schedulesAreLoading(false)));
  };
}

export function bulkEditScheduleApi({ mode, classId, available }) {
  return async (dispatch) => {
    dispatch(schedulesAreLoading(true));
    return axios
      .post(
        BULK_EDIT_SCHEDULE_AVAILABLE_NUMBER,
        {
          mode: mode,
          classId: classId,
          available: available,
        },
        { headers: await headers() }
      )
      .then((res) => {
        if (res.status !== 200) {
          throw Error(res.statusText);
        }
        return res;
      })
      .then(() => {
        dispatch(getSchedulesApi());
        dispatch(
          showSuccessBanner(
            `Successfully updated available number in class with ID: ${classId}`
          )
        );
      })
      .catch((e) => {
        dispatch(
          showErrorBanner(
            `Failed to update available number in class with ID: ${classId}. Detail from server: ${get(
              e,
              'response.data.message',
              e.message
            )} `
          )
        );
      })
      .then(() => dispatch(schedulesAreLoading(false)));
  };
}
export function deleteAvailableScheduleApi(scheduleId) {
  const url = DELETE_AVAILABLE_SCHEDULE_ENDPOINT + '?scheduleId=' + scheduleId;
  const parameter = { scheduleId: scheduleId };

  return async (dispatch) => {
    dispatch(schedulesAreLoading(true));
    axios
      .delete(url, { headers: await headers(), parameter })
      .then((res) => {
        if (res.status !== 200) {
          throw Error(res.statusText);
        }
        dispatch(getSchedulesApi());
        dispatch(
          showSuccessBanner(
            `Schedule with Schedule Id: ${scheduleId} was successfully deleted from the database`
          )
        );
        return res;
      })
      .catch((error) => {
        dispatch(
          showErrorBanner(
            `There was an error deleting your schedule in the database. ${error.response.data.message}`
          )
        );
      })
      .then(() => dispatch(schedulesAreLoading(false)));
  };
}

export function multiDeleteAvailableSchedulesApi(currentSelections) {
  const scheduleIds = currentSelections.map((schedule) => schedule.scheduleId);
  return async (dispatch) => {
    dispatch(schedulesAreLoading(true));
    return axios
      .post(
        MULTI_DELETE_AVAILABLE_SCHEDULE_ENDPOINT,
        {
          scheduleIds: scheduleIds,
        },
        { headers: await headers() }
      )
      .then((res) => {
        if (res.status !== 200) {
          throw Error(res.statusText);
        }
        dispatch(
          showWarningBanner(
            `Failed to delete schedule(s) with ID: ${res.data.failedScheduleIds.join(
              ', '
            )}. These schedules are already selected by candidates.`
          )
        );
        return res;
      })
      .then((res) => {
        const deletedSchedules = scheduleIds.filter(
          (schedule) => !res.data.failedScheduleIds.includes(schedule)
        );
        dispatch(getSchedulesApi());
        if (deletedSchedules.length > 0) {
          dispatch(
            showSuccessBanner(
              `Successfully deleted schedules with ID: ${deletedSchedules}`
            )
          );
        }
      })
      .catch((e) => {
        dispatch(
          showErrorBanner(
            `Failed to delete schedules with ID: ${scheduleIds}. Detail from server: ${get(
              e,
              'response.data.message',
              e.message
            )} `
          )
        );
      })
      .then(() => dispatch(schedulesAreLoading(false)));
  };
}
