import {
  REQUIRED_NONEMPTY_FIELDS,
  LUNCH_HEADERS,
  REQUIRED_HEADERS,
} from '../../constants/schedulesCsv';
import { userSiteToTimeZone } from '../../constants/timezones';
import { dateValidator } from '../timeHelpers';
import { keys, isEmpty } from 'lodash';

const getCsvGeneratedJsonErrors = (schedules) => {
  const errors = new Set();

  // Check for zero schedules
  if (schedules.length === 0) {
    errors.add('Your file contains no rows of schedules');
    return Array.from(errors);
  }

  // Check for missing headers
  const headersPresent = keys(schedules[0]);

  // get all recognized user sites which contain timezone mapping
  const recognizedUserSites = Object.keys(userSiteToTimeZone);

  const headersMap = {};
  headersPresent.forEach((header) => {
    headersMap[header] = true;
  });

  for (const requiredHeader of REQUIRED_HEADERS) {
    if (!headersMap[requiredHeader]) {
      errors.add(`Your file is missing the '${requiredHeader}' header`);
    }
  }

  if (!isEmpty(errors)) {
    return Array.from(errors);
  }

  schedules.forEach((schedule) => {
    // Check for empty required fields
    for (const field of REQUIRED_NONEMPTY_FIELDS) {
      if (!schedule[field]) {
        errors.add(
          `Your file has at least one row that is missing the '${field}' field`
        );
      }

      // check if site is supported
      if (field === 'site' && !recognizedUserSites.includes(schedule[field])) {
        errors.add(
          `The site name '${schedule[field]}' is not recognized. Check spelling and try again. Still have trouble? Cut a ticket to https://w.amazon.com/bin/view/Vcs-tech/SubmitTicket/`
        );
      }
    }

    // Check for missing lunch break
    const rowHasLunchBreak = LUNCH_HEADERS.reduce((hasLunch, header) => {
      return hasLunch || Boolean(schedule[header]);
    }, false);

    if (!rowHasLunchBreak) {
      errors.add(
        'Your file has at least one row that does not specify any lunch break'
      );
    }

    // Check for non-number values in 'available' and 'durationInHours' fields
    if (isNaN(schedule.available)) {
      errors.add(
        'Your file has at least one "available" value that is not a number'
      );
    }
    if (isNaN(schedule.durationInHours)) {
      errors.add(
        'Your file has at least one "Duration" value that is not a number'
      );
    }
    if (!dateValidator(schedule.dayOne)) {
      errors.add(
        `Day one '${schedule.dayOne}' is not in correct format: MM/DD/YYYY`
      );
    }
  });

  return Array.from(errors);
};

export default getCsvGeneratedJsonErrors;
