import { WEEKDAYS, CAPITAL_WEEKDAYS_MAPPING } from '../constants/days';
export const SCHEDULE_FIELDS = [
  'scheduleId',
  'available',
  'classId',
  'dayOne',
  'startTime',
  'durationInHours',
  'ouId',
  'site',
  'language',
  'workGroup',
  ...WEEKDAYS,
];

export const SCHEDULE_FIELDS_HEADER_MAPPING = {
  scheduleId: 'Schedule Id',
  available: 'Available',
  classId: 'Class Id',
  dayOne: 'DayOne',
  startTime: 'Start Time',
  durationInHours: 'Duration In Hours',
  ouId: 'OU',
  site: 'Site',
  language: 'Language',
  workGroup: 'Work Group',
  ...CAPITAL_WEEKDAYS_MAPPING,
};

export const SCHEDULE_DELETE_DISPLAY_FIELDS = [
  'dayOne',
  'available',
  'ouId',
  'site',
  'workGroup',
];

export const SCHEDULE_EDITING_MODE_MAPPING = {
  'Add slots': 'ADD',
  'Remove slots': 'REMOVE',
  'Set to': 'SET',
};
