import csv from 'csvtojson';

const convertCsvToJson = (csvString) => {
  return new Promise((resolve) => {
    csv({ output: 'json' })
      .fromString(csvString)
      .then((jsonObj) => {
        resolve(jsonObj);
      });
  });
};

export { convertCsvToJson };
