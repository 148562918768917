import React from 'react';

const CsvFormatWarningComponent = () => (
  <div>
    File type is not supported. You can save file as UTF-8 using Excel by
    <ol>
      <li>
        Click <b>File</b> then <b>Save As</b>
      </li>
      <li>
        In the "Save As" window select <b>Browse</b>
      </li>
      <li>
        <b>(if Excel 2017 or greater)</b> In the "Save As" dialog, click{' '}
        <b>Save as type</b> dropdown. Select the{' '}
        <b>CSV UTF-8 (comma delimited) (*.csv)</b> option{' '}
      </li>
      <li>
        <b>(if Excel 2016 or less)</b> In the "Save As" dialog, click{' '}
        <b>Save as type</b> dropdown. Select the{' '}
        <b>CSV UTF-8 (comma delimited) (*.csv)</b> option. Then click on the{' '}
        <b>Tools</b> dropdown, and <b>Select Web Options</b>. Navigate to{' '}
        <b>Encoding</b> tab and select <b>Unicode (UTF-8)</b> option
      </li>
      <li>
        Click the <b>Save</b> button
      </li>
    </ol>
  </div>
);

export default CsvFormatWarningComponent;
