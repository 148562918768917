import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  ColumnLayout,
  ExpandableSection,
  Modal,
} from '@amzn/awsui-components-react/polaris';
import PropTypes from 'prop-types';
import {
  SCHEDULE_FIELDS_HEADER_MAPPING,
  SCHEDULE_DELETE_DISPLAY_FIELDS,
} from '../../constants/scheduleDataConfig';
import { get } from 'lodash';
import '../styles/multiDeleteSchedulesModal.scss';
import { multiDeleteAvailableSchedulesApi } from '../actions/schedules';

const MultiDeleteSchedulesModal = ({
  currentSelections,
  isModalVisible,
  setIsModalVisible,
}) => {
  const dispatch = useDispatch();
  const onClose = () => {
    setIsModalVisible(false);
  };
  const onSubmit = () => {
    dispatch(multiDeleteAvailableSchedulesApi(currentSelections));
    setIsModalVisible(false);
  };
  const modalHeaderText = 'Are you sure you want to delete these schedules?';
  const modalCancelText = 'Cancel';
  const modalSubmitText = 'Delete';

  return (
    <Modal
      onDismiss={onClose}
      visible={isModalVisible}
      header={modalHeaderText}
      footer={
        <span className="awsui-util-f-r">
          <Button variant="link" onClick={onClose}>
            {modalCancelText}
          </Button>
          <Button variant="primary" onClick={onSubmit}>
            {modalSubmitText}
          </Button>
        </span>
      }
    >
      <ColumnLayout columns={2}>
        <div className="awsui-util-action-stripe-title multi-delete-header-space">
          <h4> Schedule IDs </h4>
        </div>

        {currentSelections.map((schedule) => (
          <ExpandableSection
            header={schedule.scheduleId}
            key={schedule.scheduleId}
          >
            <div className="awsui-util-container">
              {SCHEDULE_DELETE_DISPLAY_FIELDS.map((element) => (
                <div data-awsui-column-layout-root="true" key={element}>
                  <div>
                    <b>{get(SCHEDULE_FIELDS_HEADER_MAPPING, [element], '')}</b>
                  </div>
                  <div>{get(schedule, [element], '')}</div>
                </div>
              ))}
            </div>
          </ExpandableSection>
        ))}
      </ColumnLayout>
    </Modal>
  );
};
MultiDeleteSchedulesModal.propTypes = {
  currentSelections: PropTypes.array,
  isModalVisible: PropTypes.bool,
  setIsModalVisible: PropTypes.func,
};
export default MultiDeleteSchedulesModal;
