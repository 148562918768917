import { split } from 'lodash';

export const validationErrorGenerator = (message) => {
  if (message.includes('validation errors')) {
    const errorMsg = split(message, 'detected: ');
    errorMsg[1] = split(errorMsg[1], ';');
    errorMsg[1] = errorMsg[1].map((error) => {
      return error.split(' failed to ')[0];
    });
    return errorMsg;
  } else {
    return message;
  }
};
