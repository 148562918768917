import React, { useState, useRef } from 'react';
import { readFile, getFileEncoding } from '../../helpers/fileHelper';
import { convertCsvToJson } from '../../helpers/csvHelper';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import { Button, Icon, Modal, Alert } from '@amzn/awsui-components-react';
import CsvFormatWarningComponent from '../components/CsvFormatWarningComponent';
import '../styles/uploadCsvFileModal.scss';

const UploadCsvFileModal = ({
  title,
  Description,
  Requirements,
  fileValidator,
  uploadFile,
  dataParser,
  isModalVisible,
  setIsModalVisible,
  CustomizedOptions,
}) => {
  const [fileName, setFileName] = useState('');
  const [fileJson, setFileJson] = useState({});
  const [errors, setErrors] = useState([]);

  const fileInputEl = useRef(null);

  const chooseFile = () => {
    setFileJson({});
    fileInputEl.current.click();
  };

  const handleCsvFile = (event) => {
    const files = get(event, 'target.files');
    if (!files || files.length === 0) {
      return;
    }

    let file = files[0];

    setFileName(file.name);

    getFileEncoding(file).then((encoding) => {
      readFile(file, encoding).then((csvString) => {
        if (
          csvString &&
          (csvString.includes('�') ||
            csvString.includes('‡') ||
            csvString.includes('Ž'))
        ) {
          setErrors([<CsvFormatWarningComponent />]);
          return;
        }
        convertCsvToJson(csvString).then((dataList) => {
          const errors = fileValidator(dataList);
          if (get(errors, 'length', 0) !== 0) {
            setErrors(errors);
            return;
          }
          const formattedDataList = dataParser(dataList);
          setErrors([]);
          setFileJson(formattedDataList);
        });
      });
    });
  };

  const onDismissModal = () => {
    setFileName(null);
    setFileJson(null);
    setErrors([]);
    fileInputEl.current.value = ''; // Clearing the input value so that a change will be detected if the same file is uploaded later
    setIsModalVisible(false);
  };
  const uploadButtonOnClick = () => {
    uploadFile(fileJson);
    onDismissModal();
  };
  return (
    <Modal
      visible={isModalVisible}
      onDismiss={onDismissModal}
      header={title}
      footer={
        <span className="awsui-util-f-r">
          <Button variant="link" onClick={onDismissModal}>
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={isEmpty(fileJson)}
            onClick={uploadButtonOnClick}
          >
            Upload
          </Button>
        </span>
      }
    >
      <div className="margin-bottom-10 ">
        <Description />
      </div>
      <div className="margin-bottom-10 ">
        <Requirements />
      </div>
      {CustomizedOptions && <CustomizedOptions />}
      <div className="awsui-util-action-stripe-group">
        <Button onClick={chooseFile}>
          <input
            style={{ display: 'none' }}
            ref={fileInputEl}
            type="file"
            accept=".csv"
            onChange={handleCsvFile}
          />
          <div className="awsui-util-action-stripe-group">
            <Icon name="upload" size="normal" variant="normal" />
            <div type="b200">Choose File</div>
          </div>
        </Button>
        {
          <div className="margin-top-bottom-auto">
            {fileName ? fileName : 'No file chosen'}
          </div>
        }
      </div>
      <div className="margin-bottom-top-10">
        {errors.map((error, i) => {
          return (
            <div className="margin-bottom-top-5" key={i}>
              <Alert key={i} type="warning">
                {error}
              </Alert>
            </div>
          );
        })}
      </div>
    </Modal>
  );
};
UploadCsvFileModal.propTypes = {
  title: PropTypes.string,
  Description: PropTypes.func,
  Requirements: PropTypes.func,
  fileValidator: PropTypes.func,
  uploadFile: PropTypes.func,
  dataParser: PropTypes.func,
  isModalVisible: PropTypes.bool,
  setIsModalVisible: PropTypes.func,
  CustomizedOptions: PropTypes.func,
};
export default UploadCsvFileModal;
